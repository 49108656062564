import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from './components/SignIn';
import New_SubAdminMainPage from './components/New_SubAdminMainPage'; 
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="/mainPage" element={<New_SubAdminMainPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
