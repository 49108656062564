import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import { Box } from "@mui/material";
//import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
//import date from "date-and-time";
import AdminTableComponent from './AdminTableComponent';// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-regular-svg-icons";
//import ReactLoading from "react-loading";

const SubAdminMainPage = () => {
  //const [results, setResults] = useState([]);
  //const [filteredResults, setFilteredResults] = useState([]);
  const [admin, setadmin] = useState({});
  //const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();

  const axiosInsance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SITE_BACKEND,
  });

  useEffect(() => {
    getSession();
   // getResList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = async () => {
    try {
      const rez = await axiosInsance.get(`/subadmin`);

      setadmin(rez.data);
    } catch (error) {
      if (error.response.status === 401) navigate("/signin");
      //else console.clear();
    }
  };

  // const downloadFile = async (result, sampleId) => {
  //   try {
  //     await axiosInsance
  //       .get(`/user/downloadFile/${sampleId}`, { responseType: "blob" })
  //       .then((response) => {
  //         const file = new Blob([response.data], { type: "application/pdf" });
  //         const filePdf = URL.createObjectURL(
  //           file,
  //           `${result.samplenumber}.pdf`
  //         );
  //         window.open().location = filePdf;
  //       });
  //     await axiosInsance.post(`/user/setdownload`, {
  //       downloadedfileId: sampleId,
  //       downlodedfile: result.samplenumber,
  //       usercnp: result.userCnp,
  //     });
  //   } catch (error) {
  //     if (error.response.status === 401) navigate("/signin");
  //     //else console.clear();
  //   }
  // };

  // const handleTextChange = (e) => {
  //   const rezList = [];
  //   if (e.target.value.length > 3) {
  //     for (const rez of results) {
  //       for (const result of rez.results) {
  //         if (result.samplenumber.includes(e.target.value)) rezList.push(rez);
  //       }
  //     }
  //     setFilteredResults(rezList);
  //   } else {
  //     setFilteredResults(results);
  //   }
  // };

  

  const logout = async () => {
    try {
      await axiosInsance
        .get(`${process.env.REACT_APP_SITE_BACKEND}/auth/logout`)
        .then((res) => {
          navigate("/signin");
        });
    } catch (error) {
      if (error.response.status === 401) navigate("/signin");
      //else console.clear();
    }
  };

  return (
    <div
      id="bodyStyle"
      className="layout-boxed sidebar-collapse sidebar-closed"
    >
      <div className="wrapper">
        <section className="content-header">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <div>
              <h1 style={{ fontWeight: "bold", color: "white" }}>
                {admin.unitate}
              </h1>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <button className="btn btn-block btn-danger" onClick={logout}>
                Log Out
              </button>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card card-info">
              <div className="card-header">
                <h3 className="card-title">Pacienti</h3>
                <div className="card-tools">
                  <span className="badge badge-primary"></span>
                </div>
              </div>

              <div className="card-body table-responsive p-0">
                {/* <section className="content">
                  <div className="container-fluid">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <h4 className="text-center ">Cautare: </h4>
                      <div className="col-md-2">
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control form-control-lg"
                            placeholder="Serie Formular"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </section> */}
                {/* {!isLoaded ? (
                      null
                ) : ( */}
                  <AdminTableComponent />
                {/* )} */}
              </div>
              {/* {!isLoaded ? (
                <div class="overlay">
                      <ReactLoading
                        className="w-10"
                        type={"spinningBubbles"}
                        color={"#1ecbe1"}
                        height={100}
                        width={100}
                      />
              </div>
                ) : (
                  null
                )} */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SubAdminMainPage;
