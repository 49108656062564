import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const SignIn= () => {

    const [username, setUsername] = useState("");
    const [password, setPass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const navigate = useNavigate();
    const captchaRef = useRef(null);
    const axiosInsance = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_SITE_BACKEND,
    });
    const { pass } = useParams();

    useEffect(() => {
        getSession();
        // eslint-disable-next-line
    }, []);

    const getSession = async () => {
        try {
            await axiosInsance.get(`/subadmin`).then((rez) => { navigate("/mainPage"); });

        } catch (error) {
            //console.clear();
            if (pass) {
                setPass(pass);
            }
        }
    }

    const signIn = async (e) => {
        e.preventDefault();
        try {
            const token = captchaRef.current.getValue();
            const res = await axiosInsance.post(`/auth/subadmin/login`, { username, password, captchaToken: token });
            console.log(res);
            navigate("/mainPage");
        } catch (error) {
            console.log(error);
            setErrorMessage(error.response.data.message);
            //console.clear();
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
          setPasswordType("text");
          return;
        }
        setPasswordType("password");
      };

    return(
        <div id="logInBodyStyle"  className="login-page">
                <div className="login-box">
                    <div className="card card-outline card-primary">
                        <h2 id="headerTitle">Log In</h2>
                        <div>
                            <div id="text" className="row">
                                <text>{errorMessage}</text>
                            </div>
                            <div className="row">
                                <label></label>
                                <input type="text" placeholder="User" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="row">
                                <label></label>
                                {/* <input type="password" placeholder="Parola" value={password} onChange={(e) => setPass(e.target.value)} /> */}
                                <input
                  type={passwordType}
                  placeholder="Parola"
                  value={password}
                  maxLength={15}
                  onChange={(e) => setPass(e.target.value)}
                />
                <button
                  className="btn btn-outline-primary"
                  onClick={togglePassword}
                  style={{
                    position: "absolute",
                    background: "none",
                    width: "10%",
                    left: "80%",
                    top: "52%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {passwordType === "password" ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{ color: "gray" }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faEye} style={{ color: "gray" }} />
                  )}
                </button>
                            </div>
                            <div id="capcha" className="row">
                                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} hl={'ro'} ref={captchaRef} />
                            </div>
                            <div id="button" className="row">
                                <button onClick={signIn}>Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default SignIn;