
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import date from "date-and-time";

const AdminTableComponent = () => {

  // const [results, setResults] = useState([]);
  // const [filteredResults, setFilteredResults] = useState([]);
  // const [admin, setadmin] = useState({});
  // const [isLoaded, setIsLoaded] = useState(false);

  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const navigate = useNavigate();

  const axiosInsance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SITE_BACKEND,
  });

  const getLetter = async (result) => {
    try {
      await axiosInsance
        .get(`/user/getLetter/${result.samplenumber}`, { responseType: "blob" })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const filePdf = URL.createObjectURL(file, `Scrisoare.pdf`);
          window.open().location = filePdf;
        });
    } catch (error) {
      if (error.response.status === 401) navigate("/signin");
      else console.clear();
    }
  };

  const downloadFile = async (result, sampleId) => {
    try {
      await axiosInsance
        .get(`/user/downloadFile/${sampleId}`, { responseType: "blob" })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const filePdf = URL.createObjectURL(
            file,
            `${result.samplenumber}.pdf`
          );
          window.open().location = filePdf;
        });
      await axiosInsance.post(`/user/setdownload`, {
        downloadedfileId: sampleId,
        downlodedfile: result.samplenumber,
        usercnp: result.userCnp,
      });
    } catch (error) {
      if (error.response.status === 401) navigate("/signin");
      else console.clear();
    }
  };

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        console.log("Making Table!!!");
        const response = await axiosInsance.post(
          `${process.env.REACT_APP_SITE_BACKEND}/subadmin/getUsers`, {start: pagination.pageIndex * pagination.pageSize, size: pagination.pageSize, filters: columnFilters, globalFilter: globalFilter, sorting: sorting}
        );
        console.log(response.data);
        //const json = await response.json();
        setData(response.data);
        if(response.data.length !==0 )
        {
          setRowCount(response.data[0].totalRowCount);
        }else {
          setRowCount(0);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "cnp",
        header: "CNP",
        size: 50,
       
      },
      {
        accessorKey: "lastname",
        header: "Nume",
      },
      {
        accessorKey: "firstname",
        header: "Prenume",
      }
    ],
    []
  );

  const subRows = useMemo(() => [
    {
      accessorKey: "samplenumber",
      header: "Samplenumber",
      size: 50,
     
    },
    {
      accessorKey: "date",
      header: "Date",
    },
  ],
  [])

  const table = useMaterialReactTable({
    columns,
    data,
    subRows,
    enableExpanding: true,
    getRowId: (row) => row.cnp,
    initialState: { showColumnFilters: false, showGlobalFilter: true },
    muiSearchTextFieldProps: {placeholder: "Serie Formular"},
    enableFilterMatchHighlighting: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    renderDetailPanel: ( row ) => (
      <Box
        sx={{
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
        }}
      >
        <section className="content">
          <div className="container-fluid">
            <div className="card card-info">
              <div className="card-header">
                <h3 className="card-title">Rezultate analize</h3>
                <div className="card-tools">
                  <span className="badge badge-primary"></span>
                </div>
              </div>

              <div className="card-body">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper dt-bootstrap4"
                >
                  <table
                    id="example2"
                    className="table table-striped  dataTable dtr-inline"
                    aria-describedby="example2_info"
                  >
                    <thead>
                      <tr>
                        <th
                          className="sorting sorting_asc"
                          tabIndex="0"
                          aria-controls="example2"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Serie Formular: activate to sort column descending"
                          aria-sort="ascending"
                        >
                          Serie Formular
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="example2"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Data prelevării: activate to sort column ascending"
                          style={{}}
                        >
                          Data prelevării
                        </th>
                        <th>CNP</th>
                        <th>Buletin HPV</th>
                        <th>Buletin BP</th>
                        <th>Scrisoare</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.row.original.results
                        .sort((a, b) => (a.date < b.date ? 1 : -1))
                        .map((result) => (
                          <tr className="odd" key={result.samplenumber}>
                            <td
                              className="dtr-control sorting_1"
                              tabIndex="0"
                            >
                              {result.samplenumber}
                            </td>
                            <td>
                              {date.format(
                                new Date(result.date),
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{result.userCnp}</td>
                            <td>
                              {result.buletinHpv ? (
                                <button
                                  type="button"
                                  className="btn btn-block bg-gradient-primary "
                                  style={{ width: 100, fontSize: 15 }}
                                  onClick={() =>
                                    downloadFile(result, result.buletinHpv)
                                  }
                                >
                                  Deschideti
                                  {/* <FontAwesomeIcon icon={faEye} /> */}
                                </button>
                              ) : result.bpRez === "NILM" ? (
                                "Nu este necesar conform protocolului."
                              ) : (
                                "Va rugam asteptati rezultatul testului HPV"
                              )}
                            </td>
                            <td>
                              {result.buletinBp ? (
                                <button
                                  type="button"
                                  className="btn btn-block bg-gradient-primary "
                                  style={{ width: 100, fontSize: 15 }}
                                  onClick={() =>
                                    downloadFile(result, result.buletinBp)
                                  }
                                >
                                  Deschideti
                                  {/* <FontAwesomeIcon icon={faEye} /> */}
                                </button>
                              ) : result.hpvRez === "NEGATIV" ? (
                                "Nu este necesar conform protocolului."
                              ) : (
                                "Va rugam asteptati rezultatul testului BP"
                              )}
                            </td>
                            <td>
                              {result.hpvRez === "NEGATIV" ||
                              result.bpRez === "NILM" ? (
                                <button
                                  type="button"
                                  className="btn btn-block bg-gradient-primary"
                                  style={{ width: 100, fontSize: 15 }}
                                  onClick={() => getLetter(result)}
                                >
                                  Deschideti
                                  {/* <FontAwesomeIcon icon={faEye} /> */}
                                </button>
                              ) : (result.hpvRez !== "NEGATIV" &&
                                  result.bpRez === null) ^
                                (result.hpvRez === null &&
                                  result.bpRez !== "NILM") ? (
                                "Disponibil dupa finalizarea ambelor teste."
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-block bg-gradient-primary"
                                  style={{ width: 100, fontSize: 15 }}
                                  onClick={() => getLetter(result)}
                                >
                                  Deschideti
                                  {/* <FontAwesomeIcon icon={faEye} /> */}
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                Atentie! Odata cu rezultatul, va rugam sa cititi si
                scrisoarea de recomandare!
              </div>
            </div>
          </div>
        </section>
      </Box>
    )
    
  });

  return <MaterialReactTable table={table} />;
};

export default AdminTableComponent;